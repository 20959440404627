<template>
    <draggable class="grid-drop" :list="list" group="my-group" :itemKey="id" :animation="200">
        <!-- Define the item slot for each draggable item -->
        <template v-slot:item="{ element }">
            <form>
                <div class="input-wrapper">
                    <input class="drop-item" :key="element.id" v-model="element.enteredValue"
                        :placeholder="element.name" type="">
                    <v-icon name="io-close" class="input-icon"></v-icon>
                </div>
            </form>
        </template>
    </draggable>
</template>

<script>
import draggable from "vuedraggable";
import { OhVueIcon, addIcons } from 'oh-vue-icons';
import { IoCloseOutline, IoCloseCircleOutline, MdEmailOutlined, HiUser, HiUsers, RiMessage2Line, IoClose } from 'oh-vue-icons/icons';

addIcons(IoCloseOutline, IoCloseCircleOutline, MdEmailOutlined, HiUser, HiUsers, RiMessage2Line, IoClose);

export default {
    components: {
        draggable,
        "v-icon": OhVueIcon,
    },
    props: {
        list: Array,
    }
}
</script>

<style scoped>
.grid-drop {
    padding: 15px 12px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.drop-item {
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 15px;
    padding-right: 40px;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.input-wrapper {
    position: relative;
    display: block;
}

.input-icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 16px;
    width: 16px;
    padding: 10px;
    color: #c3c2c2;
    cursor: pointer;
}
</style>